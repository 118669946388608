import { Gradient } from "./Gradient.types";

// has a direction, and array of stops
export const getGradient = (): Gradient => {
  const numberOfStops = 2; // TODO support more?
  const direction = "to bottom"; // TODO support more?

  const stops = Array.from({ length: numberOfStops }, () => ({
    color: {
      red: Math.round(Math.random() * 255),
      green: Math.round(Math.random() * 255),
      blue: Math.round(Math.random() * 255),
    },
  }));

  return {
    stops: stops,
    direction: direction,
  };
};

// TODO: handle stop distance
export const gradientToCSS = (gradient: Gradient) => {
  const stops = gradient.stops
    .map(({ color: { red, green, blue } }) => {
      return `rgb(${red},${green},${blue})`;
    })
    .join(",");

  return `linear-gradient(${gradient.direction},${stops}`;
};
