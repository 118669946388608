import React from "react";
import { Button } from "@material-ui/core";
import { useDrag } from "react-use-gesture";
import { animated, useSpring } from "react-spring";

import { Gradient } from "../gradients/Gradient.types";
import GradientCard from "./GradientCard";

const clamp = (num: number, min: number, max: number) => {
  return num <= min ? min : num >= max ? max : num;
};

const cardStyle = {
  position: "absolute",
  marginLeft: "-150px",
  width: "300px",
  minHeight: "300px",
  touchAction: "none",
};

const halfWidthButtonStyle = {
  width: "50%",
};

export interface LikableGradientCardProps {
  gradient: Gradient;
  style: React.CSSProperties;
  liked: () => void;
  disliked: () => void;
}

const LikableGradientCard: React.FC<LikableGradientCardProps> = ({
  gradient,
  style,
  liked,
  disliked,
}) => {
  // create a spring to animate the card
  const [{ xy, opacity }, set] = useSpring(() => ({
    xy: [0, 0],
    opacity: 1,
  }));

  // create a binding for swipe/drag events
  const bind = useDrag(
    ({ swipe: [swipeXDirection], down, movement, velocity }) => {
      velocity = clamp(velocity, 1, 3);
      set({
        xy: down ? movement : [0, 0],
        config: {
          mass: velocity,
          tension: 500 * velocity,
          friction: 50,
        },
      });

      // this can probably be refactored
      if (Math.abs(movement[0]) > 300) {
        set({ opacity: 0.1 });
        // if we release the gesture and are near bounds
        if (!down) {
          movement[0] > 0 ? liked() : disliked();
          set({ opacity: 1, xy: [0, 0], config: { duration: 0 } }); // hacky: instantly snap back to center
        }
      } else {
        set({ opacity: 1 }); // return to normal opacity
      }

      // if we x-swipe left or right...
      switch (swipeXDirection) {
        case -1: // to the left, we dislike
          disliked();
          break;
        case 1: // to the right, we like
          liked();
          break;
        default:
          // otherwise, do nothing
          break;
      }
    },
    {
      bounds: { left: -350, right: 350, top: -10, bottom: 10 },
      rubberband: true,
    }
  );

  const handleButtonClick = async (action: "like" | "dislike") => {
    const isLike = action === "like";

    // fling to right/left depending on whether dis/liked - TODO: add randomness to fling path?
    await set({ xy: [isLike ? 500 : -500, 0], opacity: 0 });

    isLike ? liked() : disliked();

    // immediately snap back to 0,0
    await set({ xy: [0, 0], opacity: 1, config: { duration: 0 } });
  };

  return (
    <animated.div
      style={
        {
          ...cardStyle,
          ...style,
          transform: xy.to((x, y) => `translate3d(${x}px,${y}px,0)`),
          opacity: opacity,
        } as any // hack to fix issue with opacity: see https://github.com/pmndrs/react-spring/issues/1102
      }
      {...bind()}
    >
      <GradientCard
        gradient={gradient}
        actions={
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleButtonClick("dislike")}
              style={halfWidthButtonStyle}
            >
              DISLIKE
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick("like")}
              style={halfWidthButtonStyle}
            >
              LIKE
            </Button>
          </>
        }
      />
    </animated.div>
  );
};

export default LikableGradientCard;
