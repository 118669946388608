import React from "react";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";

import { gradientToCSS } from "../gradients/gradients";
import { Gradient } from "../gradients/Gradient.types";

import { getGradientName } from "../gradients/gradient-namer/get-gradient-name";

const cardStyle = {
  width: "300px",
  minHeight: "300px",
  touchAction: "none",
};

const gradientStyle = (gradient: Gradient): React.CSSProperties => ({
  width: "300px",
  height: "300px",
  background: gradientToCSS(gradient),
});

const colorNameStyle = {
  minHeight: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export interface GradientCardProps {
  gradient: Gradient;
  actions?: JSX.Element;
}

const GradientCard: React.FC<GradientCardProps> = ({ gradient, actions }) => {
  return (
    <Card style={cardStyle}>
      <div style={gradientStyle(gradient)}></div>
      <CardContent style={colorNameStyle}>
        <Typography>{getGradientName(gradient)}</Typography>
      </CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
};

export default GradientCard;
