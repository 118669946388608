import { useEffect, useState } from "react";
import { Gradient } from "../Gradient.types";

const GRADIENT_STORAGE_KEY = "savedGradients";
/**
 * loads gradients from local storage on mount, continues to add
 * gradients to local storage as well as the gradient state
 * ex: const {gradients, saveGradient} = useLocalGradients
 */
export const useLocalGradients = () => {
  const [gradients, setGradients] = useState<Gradient[]>([]);

  const saveGradient = (gradient: Gradient) => {
    const newGradients = [...gradients, gradient];
    setGradients(newGradients);
    localStorage.setItem(GRADIENT_STORAGE_KEY, JSON.stringify(newGradients));
  };

  useEffect(() => {
    const gradientString = localStorage.getItem(GRADIENT_STORAGE_KEY);
    const gradients = gradientString ? JSON.parse(gradientString) : [];
    setGradients(gradients);
  }, []);

  return { gradients, saveGradient };
};
