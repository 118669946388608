import { HsvNamedColor } from "../Color.types";

export const colorList: HsvNamedColor[] = [
  {
    name: "Pink",
    hsv: { h: 0.970899470899471, s: 0.24705882352941178, v: 1 },
  },
  {
    name: "Light Pink",
    hsv: { h: 0.9748858447488584, s: 0.28627450980392155, v: 1 },
  },
  {
    name: "Hot Pink",
    hsv: { h: 0.9166666666666666, s: 0.5882352941176471, v: 1 },
  },
  {
    name: "Deep Pink",
    hsv: { h: 0.9099290780141844, s: 0.9215686274509804, v: 1 },
  },
  {
    name: "Pale Violet Red",
    hsv: {
      h: 0.9454828660436138,
      s: 0.4885844748858447,
      v: 0.8588235294117647,
    },
  },
  {
    name: "Medium Violet Red",
    hsv: {
      h: 0.8951310861423222,
      s: 0.8944723618090452,
      v: 0.7803921568627451,
    },
  },
  {
    name: "Light Salmon",
    hsv: { h: 0.047619047619047616, s: 0.5215686274509803, v: 1 },
  },
  {
    name: "Salmon",
    hsv: {
      h: 0.017156862745098034,
      s: 0.5439999999999999,
      v: 0.9803921568627451,
    },
  },
  {
    name: "Dark Salmon",
    hsv: {
      h: 0.042042042042042045,
      s: 0.4763948497854077,
      v: 0.9137254901960784,
    },
  },
  {
    name: "Light Coral",
    hsv: { h: 0, s: 0.4666666666666667, v: 0.9411764705882353 },
  },
  {
    name: "Indian Red",
    hsv: { h: 0, s: 0.551219512195122, v: 0.803921568627451 },
  },
  {
    name: "Crimson",
    hsv: {
      h: 0.9666666666666667,
      s: 0.9090909090909092,
      v: 0.8627450980392157,
    },
  },
  {
    name: "Fire Brick",
    hsv: { h: 0, s: 0.8089887640449438, v: 0.6980392156862745 },
  },
  {
    name: "Dark Red",
    hsv: { h: 0, s: 1, v: 0.5450980392156862 },
  },
  {
    name: "Red",
    hsv: { h: 0, s: 1, v: 1 },
  },
  {
    name: "Orange Red",
    hsv: { h: 0.045098039215686274, s: 1, v: 1 },
  },
  {
    name: "Tomato",
    hsv: { h: 0.025362318840579712, s: 0.7215686274509804, v: 1 },
  },
  {
    name: "Coral",
    hsv: { h: 0.04476190476190476, s: 0.6862745098039216, v: 1 },
  },
  {
    name: "Dark Orange",
    hsv: { h: 0.09150326797385622, s: 1, v: 1 },
  },
  {
    name: "Orange",
    hsv: { h: 0.10784313725490197, s: 1, v: 1 },
  },
  {
    name: "Gold",
    hsv: { h: 0.14052287581699346, s: 1, v: 1 },
  },
  {
    name: "Yellow",
    hsv: { h: 0.16666666666666666, s: 1, v: 1 },
  },
  {
    name: "Light Yellow",
    hsv: { h: 0.16666666666666666, s: 0.1215686274509804, v: 1 },
  },
  {
    name: "Lemon Chiffon",
    hsv: { h: 0.14999999999999997, s: 0.196078431372549, v: 1 },
  },
  {
    name: "Light Goldenrod Yellow",
    hsv: { h: 0.16666666666666666, s: 0.16, v: 0.9803921568627451 },
  },
  {
    name: "Papaya Whip",
    hsv: { h: 0.10317460317460315, s: 0.16470588235294115, v: 1 },
  },
  {
    name: "Moccasin",
    hsv: { h: 0.10585585585585587, s: 0.2901960784313725, v: 1 },
  },
  {
    name: "Peach Puff",
    hsv: { h: 0.07857142857142856, s: 0.27450980392156865, v: 1 },
  },
  {
    name: "Pale Goldenrod",
    hsv: {
      h: 0.15196078431372548,
      s: 0.28571428571428575,
      v: 0.9333333333333333,
    },
  },
  {
    name: "Khaki",
    hsv: { h: 0.15, s: 0.41666666666666663, v: 0.9411764705882353 },
  },
  {
    name: "Dark Khaki",
    hsv: {
      h: 0.15447154471544713,
      s: 0.4338624338624339,
      v: 0.7411764705882353,
    },
  },
  {
    name: "Cornsilk",
    hsv: { h: 0.1333333333333333, s: 0.13725490196078427, v: 1 },
  },
  {
    name: "Blanched Almond",
    hsv: { h: 0.09999999999999994, s: 0.196078431372549, v: 1 },
  },
  {
    name: "Bisque",
    hsv: { h: 0.09039548022598871, s: 0.2313725490196078, v: 1 },
  },
  {
    name: "Navajo White",
    hsv: { h: 0.09959349593495935, s: 0.32156862745098036, v: 1 },
  },
  {
    name: "Wheat",
    hsv: {
      h: 0.1085858585858586,
      s: 0.2693877551020409,
      v: 0.9607843137254902,
    },
  },
  {
    name: "Burly Wood",
    hsv: {
      h: 0.09386973180076628,
      s: 0.3918918918918919,
      v: 0.8705882352941177,
    },
  },
  {
    name: "Tan",
    hsv: {
      h: 0.09523809523809527,
      s: 0.33333333333333326,
      v: 0.8235294117647058,
    },
  },
  {
    name: "Rosy Brown",
    hsv: { h: 0, s: 0.23936170212765961, v: 0.7372549019607844 },
  },
  {
    name: "Sandy Brown",
    hsv: {
      h: 0.07657657657657659,
      s: 0.6065573770491803,
      v: 0.9568627450980393,
    },
  },
  {
    name: "Goldenrod",
    hsv: {
      h: 0.11917562724014337,
      s: 0.8532110091743119,
      v: 0.8549019607843137,
    },
  },
  {
    name: "Dark Goldenrod",
    hsv: {
      h: 0.11849710982658958,
      s: 0.9402173913043479,
      v: 0.7215686274509804,
    },
  },
  {
    name: "Peru",
    hsv: {
      h: 0.08215962441314555,
      s: 0.6926829268292682,
      v: 0.803921568627451,
    },
  },
  {
    name: "Chocolate",
    hsv: {
      h: 0.06944444444444443,
      s: 0.8571428571428571,
      v: 0.8235294117647058,
    },
  },
  {
    name: "Saddle Brown",
    hsv: {
      h: 0.06944444444444443,
      s: 0.8633093525179856,
      v: 0.5450980392156862,
    },
  },
  {
    name: "Sienna",
    hsv: {
      h: 0.05362318840579711,
      s: 0.7187499999999999,
      v: 0.6274509803921569,
    },
  },
  {
    name: "Brown",
    hsv: { h: 0, s: 0.7454545454545455, v: 0.6470588235294118 },
  },
  {
    name: "Maroon",
    hsv: { h: 0, s: 1, v: 0.5019607843137255 },
  },
  {
    name: "Dark Olive Green",
    hsv: {
      h: 0.22777777777777777,
      s: 0.5607476635514018,
      v: 0.4196078431372549,
    },
  },
  {
    name: "Olive",
    hsv: { h: 0.16666666666666666, s: 1, v: 0.5019607843137255 },
  },
  {
    name: "Olive Drab",
    hsv: {
      h: 0.22118380062305296,
      s: 0.7535211267605634,
      v: 0.5568627450980392,
    },
  },
  {
    name: "Yellow Green",
    hsv: {
      h: 0.22150537634408604,
      s: 0.7560975609756098,
      v: 0.803921568627451,
    },
  },
  {
    name: "Lime Green",
    hsv: {
      h: 0.3333333333333333,
      s: 0.7560975609756098,
      v: 0.803921568627451,
    },
  },
  {
    name: "Lime",
    hsv: { h: 0.3333333333333333, s: 1, v: 1 },
  },
  {
    name: "Lawn Green",
    hsv: { h: 0.25132275132275134, s: 1, v: 0.9882352941176471 },
  },
  {
    name: "Chartreuse",
    hsv: { h: 0.2503267973856209, s: 1, v: 1 },
  },
  {
    name: "Green Yellow",
    hsv: { h: 0.23237179487179485, s: 0.8156862745098039, v: 1 },
  },
  {
    name: "Spring Green",
    hsv: { h: 0.41633986928104577, s: 1, v: 1 },
  },
  {
    name: "Medium Spring Green",
    hsv: { h: 0.436, s: 1, v: 0.9803921568627451 },
  },
  {
    name: "Light Green",
    hsv: {
      h: 0.3333333333333333,
      s: 0.39495798319327735,
      v: 0.9333333333333333,
    },
  },
  {
    name: "Pale Green",
    hsv: {
      h: 0.3333333333333333,
      s: 0.3944223107569721,
      v: 0.984313725490196,
    },
  },
  {
    name: "Dark Sea Green",
    hsv: {
      h: 0.3333333333333333,
      s: 0.23936170212765961,
      v: 0.7372549019607844,
    },
  },
  {
    name: "Medium Sea Green",
    hsv: {
      h: 0.4075630252100841,
      s: 0.664804469273743,
      v: 0.7019607843137254,
    },
  },
  {
    name: "Sea Green",
    hsv: {
      h: 0.4068100358422939,
      s: 0.6690647482014388,
      v: 0.5450980392156862,
    },
  },
  {
    name: "Forest Green",
    hsv: {
      h: 0.3333333333333333,
      s: 0.7553956834532375,
      v: 0.5450980392156862,
    },
  },
  {
    name: "Green",
    hsv: { h: 0.3333333333333333, s: 1, v: 0.5019607843137255 },
  },
  {
    name: "Dark Green",
    hsv: { h: 0.3333333333333333, s: 1, v: 0.39215686274509803 },
  },
  {
    name: "Medium Aquamarine",
    hsv: {
      h: 0.4433656957928802,
      s: 0.5024390243902439,
      v: 0.803921568627451,
    },
  },
  {
    name: "Aqua",
    hsv: { h: 0.5, s: 1, v: 1 },
  },
  {
    name: "Cyan",
    hsv: { h: 0.5, s: 1, v: 1 },
  },
  {
    name: "Light Cyan",
    hsv: { h: 0.5, s: 0.1215686274509804, v: 1 },
  },
  {
    name: "Pale Turquoise",
    hsv: { h: 0.5, s: 0.2647058823529412, v: 0.9333333333333333 },
  },
  {
    name: "Aquamarine",
    hsv: { h: 0.4440104166666667, s: 0.5019607843137255, v: 1 },
  },
  {
    name: "Turquoise",
    hsv: {
      h: 0.48333333333333334,
      s: 0.7142857142857143,
      v: 0.8784313725490196,
    },
  },
  {
    name: "Medium Turquoise",
    hsv: {
      h: 0.49391727493917276,
      s: 0.6555023923444976,
      v: 0.8196078431372549,
    },
  },
  {
    name: "Dark Turquoise",
    hsv: { h: 0.5023923444976076, s: 1, v: 0.8196078431372549 },
  },
  {
    name: "Light Sea Green",
    hsv: {
      h: 0.49086757990867574,
      s: 0.8202247191011236,
      v: 0.6980392156862745,
    },
  },
  {
    name: "Cadet Blue",
    hsv: { h: 0.5051282051282051, s: 0.40625, v: 0.6274509803921569 },
  },
  {
    name: "Dark Cyan",
    hsv: { h: 0.5, s: 1, v: 0.5450980392156862 },
  },
  {
    name: "Teal",
    hsv: { h: 0.5, s: 1, v: 0.5019607843137255 },
  },
  {
    name: "Light Steel Blue",
    hsv: {
      h: 0.5942028985507246,
      s: 0.20720720720720723,
      v: 0.8705882352941177,
    },
  },
  {
    name: "Powder Blue",
    hsv: {
      h: 0.5185185185185185,
      s: 0.2347826086956522,
      v: 0.9019607843137255,
    },
  },
  {
    name: "Light Blue",
    hsv: {
      h: 0.5409356725146198,
      s: 0.24782608695652172,
      v: 0.9019607843137255,
    },
  },
  {
    name: "Sky Blue",
    hsv: {
      h: 0.5483333333333333,
      s: 0.42553191489361697,
      v: 0.9215686274509803,
    },
  },
  {
    name: "Light Sky Blue",
    hsv: {
      h: 0.563768115942029,
      s: 0.45999999999999996,
      v: 0.9803921568627451,
    },
  },
  {
    name: "Deep Sky Blue",
    hsv: { h: 0.5418300653594771, s: 1, v: 1 },
  },
  {
    name: "Dodger Blue",
    hsv: { h: 0.5822222222222222, s: 0.8823529411764706, v: 1 },
  },
  {
    name: "Cornflower Blue",
    hsv: {
      h: 0.6070559610705596,
      s: 0.5780590717299579,
      v: 0.9294117647058824,
    },
  },
  {
    name: "Steel Blue",
    hsv: {
      h: 0.5757575757575758,
      s: 0.611111111111111,
      v: 0.7058823529411765,
    },
  },
  {
    name: "Royal Blue",
    hsv: { h: 0.625, s: 0.7111111111111111, v: 0.8823529411764706 },
  },
  {
    name: "Blue",
    hsv: { h: 0.6666666666666666, s: 1, v: 1 },
  },
  {
    name: "Medium Blue",
    hsv: { h: 0.6666666666666666, s: 1, v: 0.803921568627451 },
  },
  {
    name: "Dark Blue",
    hsv: { h: 0.6666666666666666, s: 1, v: 0.5450980392156862 },
  },
  {
    name: "Navy",
    hsv: { h: 0.6666666666666666, s: 1, v: 0.5019607843137255 },
  },
  {
    name: "Midnight Blue",
    hsv: {
      h: 0.6666666666666666,
      s: 0.7767857142857143,
      v: 0.4392156862745098,
    },
  },
  {
    name: "Lavender",
    hsv: {
      h: 0.6666666666666666,
      s: 0.07999999999999995,
      v: 0.9803921568627451,
    },
  },
  {
    name: "Thistle",
    hsv: {
      h: 0.8333333333333334,
      s: 0.11574074074074073,
      v: 0.8470588235294118,
    },
  },
  {
    name: "Plum",
    hsv: {
      h: 0.8333333333333334,
      s: 0.27601809954751133,
      v: 0.8666666666666667,
    },
  },
  {
    name: "Violet",
    hsv: {
      h: 0.8333333333333334,
      s: 0.45378151260504207,
      v: 0.9333333333333333,
    },
  },
  {
    name: "Orchid",
    hsv: {
      h: 0.8396226415094339,
      s: 0.4862385321100917,
      v: 0.8549019607843137,
    },
  },
  {
    name: "Fuchsia",
    hsv: { h: 0.8333333333333334, s: 1, v: 1 },
  },
  {
    name: "Magenta",
    hsv: { h: 0.8333333333333334, s: 1, v: 1 },
  },
  {
    name: "Medium Orchid",
    hsv: {
      h: 0.8002645502645502,
      s: 0.5971563981042654,
      v: 0.8274509803921568,
    },
  },
  {
    name: "Medium Purple",
    hsv: {
      h: 0.721183800623053,
      s: 0.4885844748858447,
      v: 0.8588235294117647,
    },
  },
  {
    name: "Blue Violet",
    hsv: {
      h: 0.7531876138433516,
      s: 0.8097345132743362,
      v: 0.8862745098039215,
    },
  },
  {
    name: "Dark Violet",
    hsv: { h: 0.783570300157978, s: 1, v: 0.8274509803921568 },
  },
  {
    name: "Dark Orchid",
    hsv: { h: 0.7781385281385281, s: 0.7549019607843138, v: 0.8 },
  },
  {
    name: "Dark Magenta",
    hsv: { h: 0.8333333333333334, s: 1, v: 0.5450980392156862 },
  },
  {
    name: "Purple",
    hsv: { h: 0.8333333333333334, s: 1, v: 0.5019607843137255 },
  },
  {
    name: "Indigo",
    hsv: { h: 0.7628205128205128, s: 1, v: 0.5098039215686274 },
  },
  {
    name: "Dark Slate Blue",
    hsv: {
      h: 0.6901709401709403,
      s: 0.5611510791366905,
      v: 0.5450980392156862,
    },
  },
  {
    name: "Slate Blue",
    hsv: {
      h: 0.6898550724637681,
      s: 0.5609756097560975,
      v: 0.803921568627451,
    },
  },
  {
    name: "Medium Slate Blue",
    hsv: {
      h: 0.6902985074626865,
      s: 0.5630252100840336,
      v: 0.9333333333333333,
    },
  },
  {
    name: "White",
    hsv: { h: 0, s: 0, v: 1 },
  },
  {
    name: "Snow",
    hsv: { h: 0, s: 0.019607843137254943, v: 1 },
  },
  {
    name: "Honeydew",
    hsv: { h: 0.3333333333333333, s: 0.05882352941176472, v: 1 },
  },
  {
    name: "Mint Cream",
    hsv: { h: 0.41666666666666646, s: 0.039215686274509776, v: 1 },
  },
  {
    name: "Azure",
    hsv: { h: 0.5, s: 0.05882352941176472, v: 1 },
  },
  {
    name: "Alice Blue",
    hsv: { h: 0.5777777777777778, s: 0.05882352941176472, v: 1 },
  },
  {
    name: "Ghost White",
    hsv: { h: 0.6666666666666666, s: 0.027450980392156876, v: 1 },
  },
  {
    name: "White Smoke",
    hsv: { h: 0, s: 0, v: 0.9607843137254902 },
  },
  {
    name: "Seashell",
    hsv: { h: 0.0686274509803922, s: 0.06666666666666665, v: 1 },
  },
  {
    name: "Beige",
    hsv: {
      h: 0.16666666666666666,
      s: 0.10204081632653059,
      v: 0.9607843137254902,
    },
  },
  {
    name: "Old Lace",
    hsv: {
      h: 0.10869565217391304,
      s: 0.09090909090909093,
      v: 0.9921568627450981,
    },
  },
  {
    name: "Floral White",
    hsv: { h: 0.11111111111111101, s: 0.05882352941176472, v: 1 },
  },
  {
    name: "Ivory",
    hsv: { h: 0.16666666666666666, s: 0.05882352941176472, v: 1 },
  },
  {
    name: "Antique White",
    hsv: {
      h: 0.09523809523809519,
      s: 0.13999999999999996,
      v: 0.9803921568627451,
    },
  },
  {
    name: "Linen",
    hsv: {
      h: 0.08333333333333333,
      s: 0.07999999999999995,
      v: 0.9803921568627451,
    },
  },
  {
    name: "Lavender Blush",
    hsv: { h: 0.9444444444444443, s: 0.05882352941176472, v: 1 },
  },
  {
    name: "Misty Rose",
    hsv: { h: 0.01666666666666676, s: 0.11764705882352944, v: 1 },
  },
  {
    name: "Gainsboro",
    hsv: { h: 0, s: 0, v: 0.8627450980392157 },
  },
  {
    name: "Light Gray",
    hsv: { h: 0, s: 0, v: 0.8274509803921568 },
  },
  {
    name: "Silver",
    hsv: { h: 0, s: 0, v: 0.7529411764705882 },
  },
  {
    name: "Dark Grey",
    hsv: { h: 0, s: 0, v: 0.6627450980392157 },
  },
  {
    name: "Grey",
    hsv: { h: 0, s: 0, v: 0.5019607843137255 },
  },
  {
    name: "Dim Grey",
    hsv: { h: 0, s: 0, v: 0.4117647058823529 },
  },
  {
    name: "Light Slate Grey",
    hsv: { h: 0.5833333333333334, s: 0.22222222222222218, v: 0.6 },
  },
  {
    name: "Slate Grey",
    hsv: {
      h: 0.5833333333333334,
      s: 0.2222222222222222,
      v: 0.5647058823529412,
    },
  },
  {
    name: "Dark Slate Grey",
    hsv: { h: 0.5, s: 0.4050632911392405, v: 0.30980392156862746 },
  },
  {
    name: "Black",
    hsv: { h: 0, s: 0, v: 0 },
  },
];
