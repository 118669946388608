import { HashRouter, Switch, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";

import { GradrAppBar } from "./components/GradrAppBar";

import Home from "./pages/Home";
import Deck from "./pages/Deck";
import { HistoryPage } from "./pages/HistoryPage";

import { useLocalGradients } from "./gradients";

const App = () => {
  const { gradients, saveGradient } = useLocalGradients();

  return (
    <HashRouter>
      <CssBaseline />
      <GradrAppBar />
      <Switch>
        <Route exact path="/">
          <Home saveGradient={saveGradient} />
        </Route>
        <Route path="/deck">
          <Deck />
        </Route>
      </Switch>
      <Route path="/history">
        <HistoryPage gradients={gradients} />
      </Route>
    </HashRouter>
  );
};

export default App;
