import React, { useState } from "react";
import { Gradient } from "../gradients/Gradient.types";
import LikableGradientCard from "../components/LikableGradientCard";
import { getGradient } from "./../gradients/gradients";

const homeStyles = {
  display: "flex",
  flexDirection: "column" as "column", // workaround, for some reason "column" doesn't work
  alignItems: "center",
  justifyContent: "center",
  margin: "2rem",
};

interface HomeProps {
  saveGradient: (gradient: Gradient) => void;
}
const Home: React.FC<HomeProps> = ({ saveGradient }) => {
  const [gradientStack, setGradientStack] = useState([
    getGradient(),
    getGradient(),
  ]);

  // helper for removing gradients from the gradient stack
  const removeGradientFromStack = (gradient: Gradient) => {
    const updatedGradientStack = gradientStack.filter((element) => {
      return element !== gradient;
    });
    setGradientStack(updatedGradientStack);
  };

  // log that we liked it, save that like, and remove it
  const handleGradientLiked = async (gradient: Gradient) => {
    await console.log("Liked gradient: " + JSON.stringify(gradient, null, 2));
    saveGradient(gradient);
    removeGradientFromStack(gradient);
  };

  // log that we didn't like it, and remove it
  const handleGradientDisliked = async (gradient: Gradient) => {
    await console.log(
      "Disliked gradient: " + JSON.stringify(gradient, null, 2)
    );
    removeGradientFromStack(gradient);
  };

  // if we're ever low on gradients, add another
  if (gradientStack.length < 2)
    setGradientStack([...gradientStack, getGradient()]);

  return (
    <div style={homeStyles}>
      <div>
        {gradientStack.map((gradient, index) => (
          <LikableGradientCard
            key={JSON.stringify(gradient)} // hacky
            style={{ zIndex: -index }}
            gradient={gradient}
            liked={() => handleGradientLiked(gradient)}
            disliked={() => handleGradientDisliked(gradient)}
          />
        ))}
      </div>
    </div>
  );
};

export default Home;
