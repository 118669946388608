import { Gradient } from "../Gradient.types";
import { colorList } from "./colors/color-list";
import { getColorName } from "./get-color-name";

export function getGradientName(gradient: Gradient): string {
  const colorNames = gradient.stops.map((stop) =>
    getColorName(stop.color, colorList)
  );
  return colorNames.join(" into ");
}
