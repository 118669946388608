import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();
export const signIn = () => {
  const auth = getAuth();
  return signInWithPopup(auth, provider).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(errorCode, errorMessage);
  });
};
export const signOut = () => {
  const auth = getAuth();
  return auth.signOut();
};
