import { GradientColor } from "../../Gradient.types";
import { HsvColor } from "../Color.types";

//Taken from: https://gist.github.com/mjackson/5311256
export function convertRgbToHsv(rgb: GradientColor): HsvColor {
  let { red: r, green: g, blue: b } = { ...rgb };
  r /= 255;
  g /= 255;
  b /= 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    v = max;

  var d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        throw new Error("I can't believe you've done this");
    }

    h /= 6;
  }

  return { h, s, v };
}
