import React from "react";

import {
  AppBar,
  Drawer,
  IconButton,
  List,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";

import { LinkListItem } from "./LinkListItem";
import { LoginButton } from "./LoginButton";
import { isLoginEnabled } from "featureFlags";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: 240,
  },
}));

export const GradrAppBar: React.FC = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    drawerOpen: false,
  });

  const toggleDrawer = (open: boolean) => {
    setState({ ...state, drawerOpen: open });
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            GRADR.APP
          </Typography>
          {isLoginEnabled() && <LoginButton />}
        </Toolbar>
      </AppBar>
      <Drawer
        open={state.drawerOpen}
        onClose={() => toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        <List>
          <LinkListItem
            to="/"
            onMenuItemSelected={() => toggleDrawer(false)}
            text="Discover"
            icon={<ViewCarouselIcon />}
          />
          <LinkListItem
            to="/history"
            onMenuItemSelected={() => toggleDrawer(false)}
            text="History"
            icon={<ViewComfyIcon />}
          />
        </List>
      </Drawer>
    </>
  );
};
