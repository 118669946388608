import { GradientColor } from "../Gradient.types";
import { HsvColor, HsvNamedColor } from "./Color.types";
import { convertRgbToHsv } from "./colors/convert-rgb-to-hsv";

export function getColorName(
  color: GradientColor,
  colorList: HsvNamedColor[]
): string {
  const hsvColor = convertRgbToHsv(color);
  const { color: closestMatch } = getClosestColor(hsvColor, colorList);

  return `${getAdjectives(hsvColor, closestMatch.hsv)}${closestMatch.name}`;
}

/**
 * determines how "near" two hsv colors are
 * @param currentColor
 * @param targetColor
 */
function getDistance(
  { h: h1, s: s1, v: v1 }: HsvColor,
  { h: h2, s: s2, v: v2 }: HsvColor
) {
  return Math.pow(h2 - h1, 2) + Math.pow(s2 - s1, 2) + Math.pow(v2 - v1, 2);
}

export function getClosestColor(color: HsvColor, colorList: HsvNamedColor[]) {
  const placeHolderColorMatch = {
    distance: Number.POSITIVE_INFINITY,
    color: { name: "Unknown", hsv: { h: -256, s: -256, v: -256 } },
  };

  return colorList.reduce((currentClosest, testColor) => {
    const distance = getDistance(color, testColor.hsv);
    if (distance < currentClosest.distance) {
      return {
        distance,
        color: testColor,
      };
    } else {
      return currentClosest;
    }
  }, placeHolderColorMatch);
}

/**
 * Adjectives
 */

// Settings consist of a threshhold and an adjective
// if the offset for that value is less than a negative value
// or greater than a positive value
// that adjective will be chosen

export type AdjectiveThreshhold = [number, string];

//TODO: add an order component variable to give a more natural word order
/* Seems to work better without hue variables for now, leaving for revisiting later
  const hueSettings = [
    [-0.06, "Cold"],
    [-0.03, "Cool"],
    [0, ""],
    [0.03, "Mellow"],
    [0.06, "Warm"]
  ];
  */
const saturationSettings: AdjectiveThreshhold[] = [
  [-0.09, "Dull"],
  [-0.06, "Muted"],
  [-0.03, "Faded"],
  [0, ""],
  [0.03, "Bold"],
  [0.06, "Vibrant"],
  [0.09, "Intense"],
];
const valueSettings: AdjectiveThreshhold[] = [
  [-0.09, "Dark"],
  [-0.06, "Somber"],
  [-0.03, "Dim"],
  [0, ""],
  [0.03, "Light"],
  [0.06, "Bright"],
  [0.09, "Luminous"],
];

/**
 * diffs two hsv colors
 * @param currentColor
 * @param targetColor
 */
export function getColorOffset(
  { h: h1, s: s1, v: v1 }: HsvColor,
  { h: h2, s: s2, v: v2 }: HsvColor
): HsvColor {
  return { h: h1 - h2, s: s1 - s2, v: v1 - v2 };
}

/**
 *
 * @param offset how far a color is from it's closest match
 * @param adjectiveList list of adjectives and their thresholds
 * @returns an adjective (string)
 */
export function getAdjective(
  offset: number,
  adjectiveList: AdjectiveThreshhold[]
): string {
  let adjective: string;
  if (offset >= 0) {
    [, adjective] = adjectiveList
      .sort(([a], [b]) => b - a)
      .find(([threshold]) => offset > threshold) || [0, ""];
  } else {
    [, adjective] = adjectiveList
      .sort(([a], [b]) => a - b)
      .find(([threshold]) => offset < threshold) || [0, ""];
  }

  return adjective;
}
/**
 * determine the adjectives for a color based on its offset
 * from the closest matching color
 * @param color
 * @param closestMatchColor
 */
function getAdjectives(color: HsvColor, closestMatchColor: HsvColor) {
  const offset = getColorOffset(color, closestMatchColor);
  const valueAdjective = getAdjective(offset.v, valueSettings);
  const saturationAdjective = getAdjective(offset.s, saturationSettings);
  const adjectives = [valueAdjective, saturationAdjective]
    .join(" ")
    .trimStart();
  return adjectives === "" ? "" : adjectives + " ";
}
