import React from "react";
import { makeStyles } from "@material-ui/core";
import GradientCard from "components/GradientCard";
import { Gradient } from "gradients";

interface HistoryProps {
  gradients: Gradient[];
}
const useClasses = makeStyles({
  historyContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "0 10% 0 10%",
  },
  cardContainer: {
    margin: "8px",
  },
});
export const HistoryPage: React.FC<HistoryProps> = ({ gradients }) => {
  const classes = useClasses();
  return (
    <div className={classes.historyContainer}>
      {gradients.map((gradient) => (
        <div className={classes.cardContainer}>
          <GradientCard gradient={gradient} />
        </div>
      ))}
    </div>
  );
};
